import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { pxToRem } from '../../../../styles/themes/vad/constants';
import { fontSize } from '../../../../styles/themes/vad/font';
import InfographicsImage from '../../../../assets/images/inforgraphics.svg';
import InfographicsMobileImage from '../../../../assets/images/infog_mobile.svg';


// TODO: Use react-truncate package when available

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    marginTop: pxToRem(78),
    marginBottom: pxToRem(78),
    [theme.breakpoints.down('md')]: {
      marginTop: pxToRem(0),
      marginBottom: pxToRem(0),
    },

    '& .rowWrapper': {
      paddingTop: pxToRem(108.25),
      paddingBottom: pxToRem(108.25),
      ...theme.mixins.paddingLeft(pxToRem(83.5)),
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        paddingTop: pxToRem(58),
        paddingBottom: pxToRem(48),
        ...theme.mixins.paddingLeft(pxToRem(24)),
        ...theme.mixins.paddingRight(pxToRem(24)),
        marginTop: pxToRem(0),
        marginBottom: pxToRem(0),
      },
    },
    '& .imageWrapper': {
      paddingTop: 0,
      paddingBottom: 0,
      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(20),
      },

      '& picture': {
        '& img': {
          height: '336px',
          [theme.breakpoints.down('md')]: {
            height: '290px',
          },
        }
      }
    },
    '& .display-picture': {
      position: 'relative',
      '& img': {
        display: 'block',
      },
    },
    '& .txt-wrap': {
      position: 'relative',
      ...theme.mixins.paddingRight(pxToRem(48)),
      ...theme.mixins.paddingLeft(pxToRem(24)),
      [theme.breakpoints.down('md')]: {
        ...theme.mixins.paddingRight(pxToRem(0)),
        ...theme.mixins.paddingLeft(pxToRem(0)),
        paddingBottom: pxToRem(36),
      },
    },
    '& .segment-title': {
      textTransform: 'uppercase',
      color: theme.palette.text.sectionHeading,
      ...theme.palette.common.ellipsis(1),
      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(10),
      },
    },
    '& .sec-title': {
      fontSize: fontSize.s72Rem,
      lineHeight: fontSize.s80Rem,
      paddingBottom: pxToRem(24),
      marginBottom: pxToRem(24),
      color: theme.palette.text.sectionHeading,
      '&:not(.no-css-border)': {
        borderBottom: theme.borders.divider,
      },
      [theme.breakpoints.down('md')]: {
        fontSize: fontSize.s32Rem,
        lineHeight: fontSize.s40Rem,
        paddingBottom: pxToRem(10),
        marginBottom: pxToRem(16),
      },

      '& h2': {
        ...theme.palette.common.ellipsis(2),
      }
    },
    '&.has-no-image': {
      '& .txt-wrap': {
        ...theme.mixins.paddingLeft(pxToRem(0)),
        maxWidth: 'none',
      },
      '& .rowWrapper': {
        ...theme.mixins.right(pxToRem(0)),

        '& .rowWrapper-before': {
          ...theme.mixins.right('50%'),
          transform: `translateX(${theme.mixins.translateX(50)}%)`,
        },
        '& .rowWrapper-after': {
          display: 'none',
        },
      },
      [theme.breakpoints.down('md')]: {
        '& .imageWrapper': {
          display: 'none',
        },
        '& .rowWrapper': {
          paddingTop: pxToRem(36),
          paddingBottom: pxToRem(36),
          '& .rowWrapper-before': {
            top: pxToRem(0),
          },
        },
        '& .txt-wrap': {
          paddingBottom: pxToRem(0),
        },
      },
    },
  },
  wrapperBackground: {
    backgroundColor: props =>
      get(
        props,
        'fields.backgroundColor.fields.colorCode.value',
        theme.palette.background.InfoBG
      ) === ''
        ? theme.palette.background.InfoBG
        : get(
          props,
          'fields.backgroundColor.fields.colorCode.value',
          theme.palette.background.InfoBG
        ),
  },
  infographicsImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    ...theme.mixins.right(pxToRem(30)),
    backgroundImage: props => `url(${get(props, 'fields.supergraphicImage.value.src') || InfographicsImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'right',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: props => `url(${get(props, 'fields.supergraphicImageMobile.value.src') || InfographicsMobileImage})`,
      ...theme.mixins.right(0),
    },

    '[dir="rtl"] &': {
      transform: 'scaleX(-1)'
    }
  },
  infoboxWrapper: {
    [theme.breakpoints.down('md')]: {
      marginLeft: pxToRem(0),
      marginRight: pxToRem(0),
    },
  },
  actionBlock: {
    ...theme.mixins.marginRight(pxToRem(-22)),
    [theme.breakpoints.up('md')]: {
      ...theme.mixins.marginRight(pxToRem(-30)),
    },
    '& .btn-link': {
      ...theme.mixins.marginRight(pxToRem(30)),
      marginBottom: pxToRem(30),
      display: 'inline-block',

      [theme.breakpoints.down('md')]: {
        marginBottom: pxToRem(8),
        ...theme.mixins.marginRight(pxToRem(22)),
      },
      '& .btn-txt': {
        ...theme.palette.common.ellipsis(1),
      },
      '&:last-child': {
        // ...theme.mixins.marginRight(pxToRem(0)),
        marginBottom: pxToRem(0),
      },
    },
  },
}));

export default useStyles;
